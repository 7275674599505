import React, { Component } from "react";
import {
  SettingOutlined,
  UserOutlined,
  BellOutlined,
  SearchOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Drawer, Input, Menu, Popover, Card } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
import ProfileComponent from "./ProfileComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Percentage from "../../assets/images/percent 1.png";

const result = [{}];

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const logOutFunction = () => {
      localStorage.clear();
      window.location.reload(true);
    };
    return (
      <>
        <Menu mode="horizontal">
          {localStorage.getItem("token") ? (
            <>
              <Menu.Item key="searchInput">
                {/* { this.props.visibleInput && 
           <div ref={this.props.wrapperRef}><Input prefix={<SearchOutlined />} placeholder='Search' type="text"/></div>} */}
              </Menu.Item>

              <Menu.Item key="search" onClick={this.props.inputFunction}>
                {/* <span><SearchOutlined style={{color:"#FFFFFF"}} className="nav-icon mr-0" /></span> */}
              </Menu.Item>
            </>
          ) : (
            <Menu.Item key="search">
              {/* <span><SearchOutlined className="nav-icon mr-0" /></span> */}
            </Menu.Item>
          )}
            {localStorage.getItem("token") ? (
            <Menu.Item key="percentage">
              <span onClick={this.props.onOpenPercentage}>
                <img src={Percentage} />
              </span>
            </Menu.Item>
          ) : (
            <Menu.Item key="percentage">
              {/* <span><BellOutlined className="nav-icon mr-0" /></span> */}
            </Menu.Item>
          )}
          {localStorage.getItem("token") ? (
            <Menu.Item style={{
              paddingTop:"4px"
            }} key="qr">
              <span  onClick={this.props.modalQRVisible}>
                <QrcodeOutlined
                  style={{ color: "#FFFFFF", }}
                  className="nav-icon mr-0"
                />
              </span>
            </Menu.Item>
          ) : (
            <Menu.Item key="qr">
              {/* <span><BellOutlined className="nav-icon mr-0" /></span> */}
            </Menu.Item>
          )}

        
          {/* <Menu.Item key="setting" onClick={this.showDrawer}>
            <span><SettingOutlined style={{color:"#FFFFFF"}} className="nav-icon mr-0" /></span>
          </Menu.Item> */}
          {localStorage.getItem("token") ? (
            <Menu.Item key="avatar">
              <Popover
                placement="bottomRight"
                content={
                  <ProfileComponent
                    name={this.props.name}
                    image={this.props.image}
                    id={this.props.id}
                    logOutFunction={logOutFunction}
                  />
                }
                trigger="click"
                showArrow={false}
              >
                <Badge dot>
                  <Avatar
                    size={30}
                    className="nav-icon mr-0"
                    style={{ backgroundColor: "#87d068" }}
                    icon={<UserOutlined />}
                  />
                </Badge>
              </Popover>
            </Menu.Item>
          ) : (
            <Menu.Item key="avatar">
              {/* <Badge dot >
    <Avatar size={30} className="nav-icon mr-0" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
  </Badge> */}
            </Menu.Item>
          )}
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
        {/* {
           <Card
          style={{
            width: "20%",
            border:"1px solid #E6EBF1",
            borderRadius: "8px",
            position:"absolute",
            top:"6",
            right:"15%",
            zIndex:100,
            maxHeight:"50%",
            overflowY:"scroll"
          }}
        >
        {
        result.length==0 ? "No Result Found" : result?.map((obj)=>{
           return  <div style={{ display: "flex",marginBottom:"15px",alignItems:"flex-start"}}>
           <img src={obj?.userAvatar} width="40px" height="40px" style={{borderRadius:"50%",objectFit:"cover"}}/>
           <div style={{ marginLeft: "8px" }}>
             <div
               style={{
                 fontWeight: "700",
                 fontSize: "14px",
                 lineHeight: "17px"
               }}
             >
                <a
                  
                  style={{ color: "black" }}
                  state={{userId:obj?._id}}
                >
                <p>{`${obj?.fullName}`}</p>
                </a>
             </div>
             <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                    marginTop:"-10px"
                  }}
                >
                  {obj.phoneNumber}
                </div>
           </div>
         </div>
         }) 
         } 
        </Card>
        } */}
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
