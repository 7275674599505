import React from 'react'
import {LogoutOutlined,EditOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Avatar from "../../assets/images/Avatar.png"

function profileComponent({logOutFunction,name,image,id}) {
 
  return (  
    <div>
         <div className="profile-card">
          <div className="profile-menu-list">
            <div style={{ display: "flex" }}>
              <img src={image} width="40px" height="40px" style={{borderRadius:"50%",objectFit:"cover"}}/>
              <div style={{ marginLeft: "8px" }}>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "17px"
                  }}
                >
                  {/* adminInfo */}
                  {name}
                  {/* {adminInfo?.fullName} */}
                </div>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "15px",
                    marginBottom:"10px"
                  }}
                >
                  System Admin
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                marginLeft:"-15px"
              }}
            >
              <Link to={`/app/user_management/admin_list/update?id=${id}`} style={{color: "black"}}>
                <EditOutlined /> <span>Edit profile</span>
              </Link>
              {/* <button onClick={handleSignout} style={{border:"none",backgroundColor:"white",cursor:"pointer"}}> */}
              <Link onClick={logOutFunction} style={{color:"black"}}> 
               <span style={{marginRight:"5px"}}><LogoutOutlined /></span><span>Log Out</span>
              </Link>
            </div>
          </div>
        </div>
    </div>
  )
}

export default profileComponent