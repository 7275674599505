import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
// import navigationConfig from "configs/NavigationConfig";
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import IntlMessage from 'components/util-components/IntlMessage';

let breadcrumbData = { 
	'/app' : <IntlMessage id="/app" />
};


const navigationConfig = [{
	key: "d",
	// path: `${APP_PREFIX_PATH}/dashboard`,
	path: "",
	title: " ",
	icon: "",
	breadcrumb: false,
	submenu: [
	  {
		key: "dashboard",
		// path: `${APP_PREFIX_PATH}/dashboard`,
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		// icon: DashboardActiveIcon,
		breadcrumb: true,
		submenu: []
	  },
	  {
		key: 'membership',
		  path: `${APP_PREFIX_PATH}/membership`,
		  title: 'membership',
		//   icon: MembershipActiveIcon,
		  breadcrumb: true,
		  submenu: [
			{
			  key: 'members',
			  path: `${APP_PREFIX_PATH}/membership/members`,
			  title: 'members',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key: 'active_members',
				path: `${APP_PREFIX_PATH}/membership/members?status=active`,
				title: 'active_members',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'members_expired',
				path: `${APP_PREFIX_PATH}/membership/members?status=expired`,
				title: 'members_expired',
				submenu: []
			  },
			  {
				key:'members_suspended',
				path: `${APP_PREFIX_PATH}/membership/members?status=suspended`,
				title: 'members_suspended',
				submenu: []
			  },
			  {
				key: 'members_terminated',
				path: `${APP_PREFIX_PATH}/membership/members?status=terminated`,
				title: 'members_terminated',
				submenu: []
			  },
			  {
				key: 'membership_request',
				path: `${APP_PREFIX_PATH}/membership/membership_request?status=pending`,
				title: 'membership_request',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key:'members_rejected',
				path: `${APP_PREFIX_PATH}/membership/membership_request?status=rejected`,
				title: 'members_rejected',
				submenu: []
			  },
			{
			  key: 'membership_request',
			  path: `${APP_PREFIX_PATH}/membership/membership_request`,
			  title: 'membership_request',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
			  key: 'payments',
			  path: `${APP_PREFIX_PATH}/membership/payments`,
			  title: 'payments',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
			  key: 'membership_plans',
			  path: `${APP_PREFIX_PATH}/membership/membership_plans`,
			  title: 'membership_plans',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key:'guest',
				path:`${APP_PREFIX_PATH}/membership/guest`,
				title:'guest',
				breadcrumb:true,
				submenu:[]
			},
			{
					
				key: 'memberdetails',
				path: `${APP_PREFIX_PATH}/membership/members/membersdetails`,
				title: 'membersdetails',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
		
			},
			{
					
				key: 'update',
				path: `${APP_PREFIX_PATH}/membership/membership_plans/update`,
				title: 'Update',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
		
			},
			{
					
				key: 'add_new',
				path: `${APP_PREFIX_PATH}/membership/membership_plans/add_new`,
				title: 'Add New',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
		
			},

			
		  ]
	  },
	  {
		key: 'event_management',
		  path: `${APP_PREFIX_PATH}/events`,
		  title: 'event_management',
		//   icon: EventsActiveIcon,
		  breadcrumb: true,
		  submenu: [
			{
			  key: 'event_booking',
			  path: `${APP_PREFIX_PATH}/events/event_booking`,
			  title: 'event_booking',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
					
				key: 'view_details',
				path: `${APP_PREFIX_PATH}/events/view_details`,
				title: 'View Details',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
		
		},
		{
					
			key: 'update',
			path: `${APP_PREFIX_PATH}/events/event_list/update`,
			title: 'update',
			// icon: DashboardOutlined,
			breadcrumb: true,
			submenu: []
	
	},
		{
					
			key: 'invoice',
			path: `${APP_PREFIX_PATH}/events/view_details/invoice`,
			title: 'invoice',
			// icon: DashboardOutlined,
			breadcrumb: true,
			submenu: []
	
	},
	{
					
		key: 'invoice',
		path: `${APP_PREFIX_PATH}/facility/viewdetails/invoice`,
		title: 'invoice',
		// icon: DashboardOutlined,
		breadcrumb: true,
		submenu: []

},
			{
			  key: 'event_list',
			  path: `${APP_PREFIX_PATH}/events/event_list`,
			  title: 'event_list',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
			  key: 'sport-event-funds',
			  path: `${APP_PREFIX_PATH}/events/sport-event-funds`,
			  title: 'Sport Event Funds',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
		  ]
	  },
	  {
		key: 'facility_management',
		  path: `${APP_PREFIX_PATH}/facilities`,
		  title: 'facility_management',
		//   icon: FacilityActiveIcon,
		  breadcrumb: true,
		  submenu: [
			{
			  key: 'facility_booking',
			  path: `${APP_PREFIX_PATH}/facilities/facility_booking`,
			  title: 'facility_booking',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key: 'viewdetails',
				path: `${APP_PREFIX_PATH}/facilities/viewdetails`,
				title: 'View Details',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			{
			  key: 'facility_list',
			  path: `${APP_PREFIX_PATH}/facilities/facility_list`,
			  title: 'facility_list',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key: 'add_new',
				path: `${APP_PREFIX_PATH}/facilities/facility_list/add_new`,
				title: 'Add New',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'update',
				path: `${APP_PREFIX_PATH}/facilities/facility_list/update`,
				title: 'Update',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'facility_type',
				path: `${APP_PREFIX_PATH}/facilities/facility_list/facility_type`,
				title: 'Facility Type',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'Update',
				path: `${APP_PREFIX_PATH}/facilities/facility_list/facility_type/update`,
				title: 'Update',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
		  ]
	  },
	  {
		key: 'sports_management',
		  path: `${APP_PREFIX_PATH}/sports_management`,
		  title: 'sports_management',
		//   icon: FacilityActiveIcon,
		  breadcrumb: true,
		  submenu: [
			{
			  key: 'sports_section',
			  path: `${APP_PREFIX_PATH}/sports_management/sports_section`,
			  title: 'sports_section',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key: 'section_members',
				path: `${APP_PREFIX_PATH}/sports_management/section_members`,
				title: 'section_members',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'addNew',
				path: `${APP_PREFIX_PATH}/sports_management/sports_section/addNew`,
				title: 'addNew',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'view_activities',
				path: `${APP_PREFIX_PATH}/sports_management/sports_section/view_activities`,
				title: 'view_activities',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'addNew',
				path: `${APP_PREFIX_PATH}/sports_management/sports_section/view_activities/addNew`,
				title: 'addNew',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
		  ]
	  },
	  {
		key: 'gallery_management',
		  path: `${APP_PREFIX_PATH}/gallery_management`,
		  title: 'gallery_management',
		//   icon: GalleryActiveIcon,
		  breadcrumb: true,
		  submenu: [
			// {
			//   key: 'Gallery',
			//   path: `${APP_PREFIX_PATH}/gallery_management/gallery`,
			//   title: 'Gallery',
			//   // icon: DashboardOutlined,
			//   breadcrumb: true,
			//   submenu: []
			// },
			{
				key: 'gallery_category',
				path: `${APP_PREFIX_PATH}/gallery_management/gallery_category`,
				title: 'gallery_category',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			{
					
				key: 'addNew',
				path: `${APP_PREFIX_PATH}/gallery_management/gallery_category/addNew`,
				title: 'addNew',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			},
			{
					
				key: 'gallery',
				path: `${APP_PREFIX_PATH}/gallery_management/gallery`,
				title: 'gallery',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			},
			{
					
				key: 'editdetails',
				path: `${APP_PREFIX_PATH}/gallery_management/gallery_category/update`,
				title: 'editdetails',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			}
		  ]
	  },
	  {
		key: 'user_management',
		  path: `${APP_PREFIX_PATH}/user_management`,
		  title: 'user_management',
		//   icon: GalleryActiveIcon,
		  breadcrumb: true,
		  submenu: [
			// {
			//   key: 'Gallery',
			//   path: `${APP_PREFIX_PATH}/gallery_management/gallery`,
			//   title: 'Gallery',
			//   // icon: DashboardOutlined,
			//   breadcrumb: true,
			//   submenu: []
			// },
			{
			  key: 'approver_list',
			  path: `${APP_PREFIX_PATH}/user_management/approver_list`,
			  title: 'approver_list',
			  // icon: DashboardOutlined,
			  breadcrumb: true,
			  submenu: []
			},
			{
				key: 'addNew',
				path: `${APP_PREFIX_PATH}/user_management/addNew`,
				title: 'addNew',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'update',
				path: `${APP_PREFIX_PATH}/user_management/update`,
				title: 'update',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				key: 'admin_list',
				path: `${APP_PREFIX_PATH}/user_management/admin_list`,
				title: 'admin_list',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
			  {
				  key: 'addNew',
				  path: `${APP_PREFIX_PATH}/user_management/admin_list/addNew`,
				  title: 'addNew',
				  // icon: DashboardOutlined,
				  breadcrumb: true,
				  submenu: []
				},
				{
				  key: 'update',
				  path: `${APP_PREFIX_PATH}/user_management/admin_list/update`,
				  title: 'update',
				  // icon: DashboardOutlined,
				  breadcrumb: true,
				  submenu: []
				},
		  ]
	  },
	  {
		key:"cms",
		path:`${APP_PREFIX_PATH}/cms`,
		title:"cms",
		breadcrumb:true,
		submenu:[
			{
				key:"terms_and_conditions",
				path:`${APP_PREFIX_PATH}/cms/terms_and_conditions`,
				title:"terms_and_conditions",
				breadcrumb:true,
				submenu:[]
			},
			{
				key: 'constitution',
				path: `${APP_PREFIX_PATH}/cms/constitution`,
				title: 'constitution',
				// icon: DashboardOutlined,
				breadcrumb: true,
				submenu: []
			  },
		]
	  }
	  
  
	]
  }]

navigationConfig.forEach((elm, i) => {
	const assignBreadcrumb = (obj) => breadcrumbData[obj.path] = <IntlMessage id={obj.title} />;
	assignBreadcrumb(elm);
	if (elm.submenu) {
		elm.submenu.forEach( elm => {
			assignBreadcrumb(elm)
			if(elm.submenu) {
				elm.submenu.forEach( elm => {
					assignBreadcrumb(elm)
				})
			}
		})
	}
})

const BreadcrumbRoute = withRouter(props => {
	const { location } = props;
	const pathSnippets = location.pathname.split('/').filter(i => i);
	const buildBreadcrumb = pathSnippets.map((_, index) => {
    let url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
	if(index===pathSnippets.length-1 && location.search){
		url=url;

		if(location.search.includes("?")){
		if(location.search.includes("status") && !location.search.includes("event")){
				url+=location.search;
				console.log("h,m")
			}else{
				console.log("yedas")
			}
		}
	}
	if(url!=="/app"){

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbData[url]}</Link>
      </Breadcrumb.Item>
    );
	}

	});
  
  return (
		<Breadcrumb>
			{buildBreadcrumb}
		</Breadcrumb>
  );
});

export class AppBreadcrumb extends Component {
	render() {
		return (
			<BreadcrumbRoute />
		)
	}
}

export default AppBreadcrumb
