import {
  DashboardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
// import Icon from '@ant-design/icons-svg'

import {DashboardActiveIcon, CMSIcon , EventsActiveIcon, FacilityActiveIcon, MembershipActiveIcon,GalleryActiveIcon,UserActiveIcon,SportsActiveIcon} from './SideBarIcons'

const dashBoardNavTree = [{
  key: "d",
  // path: `${APP_PREFIX_PATH}/dashboard`,
  path: "",
  title: " ",
  icon: "",
  breadcrumb: false,
  submenu: [
    {
      key: "dashboard",
      // path: `${APP_PREFIX_PATH}/dashboard`,
      path: `${APP_PREFIX_PATH}/dashboard`,
      title: "dashboard",
      icon: DashboardActiveIcon,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'membership',
        path: `${APP_PREFIX_PATH}/membership`,
        title: 'membership',
        icon: MembershipActiveIcon,
        breadcrumb: true,
        submenu: [
          {
            key: 'active_members',
            path: `${APP_PREFIX_PATH}/membership/members?status=active`,
            title: 'active_members',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'members_expired',
            path: `${APP_PREFIX_PATH}/membership/members?status=expired`,
            title: 'members_expired',
            submenu: []
          },
          {
            key:'members_suspended',
            path: `${APP_PREFIX_PATH}/membership/members?status=suspended`,
            title: 'members_suspended',
            submenu: []
          },
          {
            key: 'members_terminated',
            path: `${APP_PREFIX_PATH}/membership/members?status=terminated`,
            title: 'members_terminated',
            submenu: []
          },
          {
            key: 'membership_request',
            path: `${APP_PREFIX_PATH}/membership/membership_request?status=pending`,
            title: 'membership_request',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key:'members_rejected',
            path: `${APP_PREFIX_PATH}/membership/membership_request?status=rejected`,
            title: 'members_rejected',
            submenu: []
          },
          {
            key: 'payments',
            path: `${APP_PREFIX_PATH}/membership/payments`,
            title: 'payments',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'membership_plans',
            path: `${APP_PREFIX_PATH}/membership/membership_plans`,
            title: 'membership_plans',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'guest',
            path: `${APP_PREFIX_PATH}/membership/guest`,
            title: 'guest',
            breadcrumb: true,
            submenu: []
          }
        ]
    },
    {
      key: 'event_management',
        path: `${APP_PREFIX_PATH}/events`,
        title: 'event_management',
        icon: EventsActiveIcon,
        breadcrumb: true,
        submenu: [
        
          {
            key: 'event_list',
            path: `${APP_PREFIX_PATH}/events/event_list`,
            title: 'event_list',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'event_booking',
            path: `${APP_PREFIX_PATH}/events/event_booking`,
            title: 'event_booking',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          // {
          //   key: 'sport-event-funds',
          //   path: `${APP_PREFIX_PATH}/events/sport-event-funds`,
          //   title: 'Sport Event Funds',
          //   // icon: DashboardOutlined,
          //   breadcrumb: true,
          //   submenu: []
          // },
        ]
    },
    {
      key: 'facility_management',
        path: `${APP_PREFIX_PATH}/facilities`,
        title: 'facility_management',
        icon: FacilityActiveIcon,
        breadcrumb: true,
        submenu: [
         
          {
            key: 'facility_list',
            path: `${APP_PREFIX_PATH}/facilities/facility_list`,
            title: 'facility_list',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'facility_booking',
            path: `${APP_PREFIX_PATH}/facilities/facility_booking`,
            title: 'facility_booking',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
    },
    {
      key: 'sports_management',
        path: `${APP_PREFIX_PATH}/sports_management`,
        title: 'sports_management',
        icon: SportsActiveIcon,
        breadcrumb: true,
        submenu: [
          {
            key: 'sports_section',
            path: `${APP_PREFIX_PATH}/sports_management/sports_section`,
            title: 'sports_section',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'section_members',
            path: `${APP_PREFIX_PATH}/sports_management/section_members`,
            title: 'section_members',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'sport_payments',
            path: `${APP_PREFIX_PATH}/sports_management/payments`,
            title: 'Payments',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
    },
    {
      key: 'gallery_management',
        path: `${APP_PREFIX_PATH}/gallery_management`,
        title: 'gallery_management',
        icon: GalleryActiveIcon,
        breadcrumb: true,
        submenu: [
          // {
          //   key: 'Gallery',
          //   path: `${APP_PREFIX_PATH}/gallery_management/gallery`,
          //   title: 'Gallery',
          //   // icon: DashboardOutlined,
          //   breadcrumb: true,
          //   submenu: []
          // },
          {
            key: 'gallery_category',
            path: `${APP_PREFIX_PATH}/gallery_management/gallery_category`,
            title: 'gallery_category',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
    },
    {
      key: 'user_management',
        path: `${APP_PREFIX_PATH}/user_management`,
        title: 'user_management',
        icon: UserActiveIcon,
        breadcrumb: true,
        submenu: [
          // {
          //   key: 'Gallery',
          //   path: `${APP_PREFIX_PATH}/gallery_management/gallery`,
          //   title: 'Gallery',
          //   // icon: DashboardOutlined,
          //   breadcrumb: true,
          //   submenu: []
          // },
          {
            key: 'approver_list',
            path: `${APP_PREFIX_PATH}/user_management/approver_list`,
            title: 'approver_list',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'admin_list',
            path: `${APP_PREFIX_PATH}/user_management/admin_list`,
            title: 'admin_list',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
    },
    {
      key: 'cms',
        path: `${APP_PREFIX_PATH}/cms`,
        title: 'CMS',
        icon: CMSIcon,
        breadcrumb: true,
        submenu: [
          {
            key: 'terms_and_conditions',
            path: `${APP_PREFIX_PATH}/cms/terms_and_conditions`,
            title: 'terms_and_conditions',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'constitution',
            path: `${APP_PREFIX_PATH}/cms/constitution`,
            title: 'Constitution',
            // icon: DashboardOutlined,
            breadcrumb: true,
            submenu: []
          },
        ]
    }
    

  ]
}]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
