import { message } from 'antd';
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import axios from 'axios'

export const AppViews = () => {
  axios.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      if (error?.response?.data.errors && error?.response?.data?.errors[0]?.message === "E_UNAUTHORIZED_ACCESS: Unauthorized access") {
        message.error("Unauthorized access")
        localStorage.removeItem('token')
        window.location.href = '/auth/login'
      }
      return Promise.reject(error);
    }
  )

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path="/app">
          <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./Dashboard`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/members`} component={lazy(() => import(`./Members`))} />
        <Route exact path={`${APP_PREFIX_PATH}/profiledetails`} component={lazy(() => import(`./Edit Profile`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/members/membersdetails`} component={lazy(() => import(`./Members/MembersDetails.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/membership_request`} component={lazy(() => import(`./Membership_Request`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/payments`} component={lazy(() => import(`./Payments/index`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/payments`} component={lazy(() => import(`./Sport_management/payments/SportPayments`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/payments/invoice`} component={lazy(() => import(`./Payments/Invoice`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/membership_plans`} component={lazy(() => import(`./Membership_plans`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/membership_plans/add_new`} component={lazy(() => import(`./Membership_plans/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/membership_plans/update`} component={lazy(() => import(`./Membership_plans/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/membership/guest`} component={lazy(() => import(`./Guest/Index`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/event_booking`} component={lazy(() => import(`./Events_Booking`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/view_details/invoice`} component={lazy(() => import(`./Payments/Invoice`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/viewdetails/invoice`} component={lazy(() => import(`./Payments/Invoice`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/event_list`} component={lazy(() => import(`./Events`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/sport-event-funds`} component={lazy(() => import(`./Sport_Event_Funds`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/sport-event-funds/add-new`} component={lazy(() => import(`./Sport_Event_Funds/AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/sport-event-funds/details`} component={lazy(() => import(`./Sport_Event_Funds/Invoice`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/event_list/add_new`} component={lazy(() => import(`./Events/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/view_details`} component={lazy(() => import(`./Events/ViewDetails.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/events/event_list/update/:id`} component={lazy(() => import(`./Events/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_booking`} component={lazy(() => import(`./Facility_Booking`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/viewdetails`} component={lazy(() => import(`./Facility/ViewDetails`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/editdetails`} component={lazy(() => import(`./Facility/EditDetails`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list`} component={lazy(() => import(`./Facility`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list/add_new`} component={lazy(() => import(`./Facility/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list/update`} component={lazy(() => import(`./Facility/AddNew.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list/facility_type`} component={lazy(() => import(`./Facility/FacilityType.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list/facility_type/add_new`} component={lazy(() => import(`./Facility/AddNewFacilityTypes.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/facilities/facility_list/facility_type/update`} component={lazy(() => import(`./Facility/AddNewFacilityTypes.js`))} />

        {/* <Route exact path={`${APP_PREFIX_PATH}/gallery_management/galleryAddNew`} component={lazy(() => import(`./gallery/addNew.js`))} /> */}
        {/* <Route exact path={`${APP_PREFIX_PATH}/gallery_management/galleryUpdate/:id`} component={lazy(() => import(`./gallery/addNew.js`))} /> */}
        <Route exact path={`${APP_PREFIX_PATH}/gallery_management/gallery_category`} component={lazy(() => import(`./galleryCategory/index`))} />
        <Route exact path={`${APP_PREFIX_PATH}/gallery_management/gallery`} component={lazy(() => import(`./gallery/index.js`))} />
        <Route exact path={`${APP_PREFIX_PATH}/gallery_management/gallery_category/addNew`} component={lazy(() => import(`./galleryCategory/addNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/gallery_management/gallery_category/update`} component={lazy(() => import(`./galleryCategory/addNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section`} component={lazy(() => import(`./Sport_management/Sports_section`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section/addNew`} component={lazy(() => import(`./Sport_management/Sports_Section_AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section/update`} component={lazy(() => import(`./Sport_management/Sports_Section_AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/section_members`} component={lazy(() => import(`./Sport_management/Sports_Club`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section/view_activities`} component={lazy(() => import(`./Sport_management/View_Activities`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section/view_activities/update`} component={lazy(() => import(`./Sport_management/Activites_AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/sports_management/sports_section/view_activities/addNew`} component={lazy(() => import(`./Sport_management/Activites_AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/approver_list`} component={lazy(() => import(`./UserMangement/approverList`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/addNew`} component={lazy(() => import(`./UserMangement/AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/update`} component={lazy(() => import(`./UserMangement/AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/admin_list`} component={lazy(() => import(`./UserMangement/AdminList/AdminList`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/admin_list/addNew`} component={lazy(() => import(`./UserMangement/AdminList/AddNew`))} />
        <Route exact path={`${APP_PREFIX_PATH}/user_management/admin_list/update`} component={lazy(() => import(`./UserMangement/AdminList/AddNew`))} />
        {/* <Route exact path={`${APP_PREFIX_PATH}/user_management/admin_list/update`} component={lazy(() => import(`./UserMangement/userList`))} /> */}
        <Route exact path={`${APP_PREFIX_PATH}/cms/terms_and_conditions`} component={lazy(() => import(`./CMS/TermsAndConditions`))} />
        <Route exact path={`${APP_PREFIX_PATH}/cms/constitution`} component={lazy(() => import(`./CMS/Constituion`))} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);