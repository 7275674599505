import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Modal,
  Button,
  Image,
  Input,
  Form,
  message,
  Select,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
  } = props;
  const [searchActive, setSearchActive] = useState(false);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [id, setId] = useState();
  const [visibleInput, setVisibleInput] = useState(false);
  const [result, setResult] = useState();
  const [modal2Open, setModal2Open] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [qrInput, setQrInput] = useState();
  const [descQR, setDescQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();
  const [percentageVisible, setPercentageVisible] = useState(false);
  const [serviceCharge, setServiceCharge] = useState({
    gst: "",
    gstSelector: "",
    serviceCharge: "",
    serviceChargeSelector: "",
  });

  const onOpenPercentage = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_BACKEND_URL_PREFIX}/service-charges `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setServiceCharge({
        gst: res.data.data[0].percent
          ? res.data.data[0].percent
          : res.data.data[0].value,
        gstSelector: res.data.data[0].percent ? "percentage" : "value",
        serviceCharge: res.data.data[1].percent
          ? res.data.data[1].percent
          : res.data.data[1].value,

        serviceChargeSelector: res.data.data[1].percent
          ? "percentage"
          : "value",
      });
    } catch (err) {}
    setPercentageVisible(true);
  };

  const onClosePercentage = () => {
    gstform.resetFields();
    setPercentageVisible(false);
  };

  const history = useHistory();

  const changeById = async (id,number,selector) => {
    try {
      axios({
        method: "put",
        // data:{id:param.id},
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_BACKEND_URL_PREFIX}/service-charges/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          value:selector==="percentage"?null:number,
          percent:selector==="value"?null:number
        },
      }).then((response) => {
        if (response.data.status) {
          if(id===2){
            setPercentageVisible(false);
            message.success("Updated Successfully");
            setLoading(false);
          }
          // gstform.resetFields();
        }
      });
    } catch (err) {
      setLoading(false);
    }
  }

  const onFinish =async () => {

    if(!serviceCharge.gst||!serviceCharge.serviceCharge){
      message.error("Please fill all the fields")
      return
    }
    setLoading(true);
    await changeById(1,serviceCharge.gst,serviceCharge.gstSelector)
    await changeById(2,serviceCharge.serviceCharge,serviceCharge.serviceChargeSelector)
   

    console.log(gstform.getFieldValue("gst"));
  };

  const [gstform] = Form.useForm();
  // useEffect(()=>{
  //   searchData();
  // },[])
  const modalQRVisible = () => {
    setModal2Open(true);
  };
  const searchData = async () => {
    axios({
      method: "post",
      // data:{id:param.id},
      url: `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_BACKEND_URL_PREFIX}/bookings`,
      headers: {
        "content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      data: {
        type: "event",
        // page_index: currPage,
        // page_size: 5,
        // // statuses: ["ACTIVE", "INACTIVE"],
        // search : searchText ? searchText : null
      },
    }).then((response) => {
      console.log(response.data);
      // if (response.data.status) {
      //   console.log(response)
      //   setTotal(response.data.result[0].meta.total)
      //     const data = response.data.result[0].data;
      //     if(currPage ===1 ){
      //       setEventsData(data);
      //   }else{
      //     setEventsData(data);
      //   }
      // } else {
      //   console.log(response)
      // }
    });
  };

  const inputFunction = () => {
    setVisibleInput(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };
  const autoFocus = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisibleInput(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
    if (localStorage.getItem("token")) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      const response = await axios({
        method: "post",
        // data:{id:param.id},
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_BACKEND_URL_PREFIX}/get-user-details`,
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        data: {
          id: 1,
        },
      });
      if (response.data.status) {
        setName(
          response.data.result[0].first_name +
            " " +
            response.data.result[0].last_name
        );
        setId(response.data.result[0].id);
        setImage(response.data.result[0].image);
      } else {
      }
    } catch (err) {
      localStorage.clear();
      window.location.reload(false);
    }
  };

  useEffect(() => {
    if (modal2Open) {
      autoFocus.current.focus();
    }
  }, [modal2Open]);

  const submitForm = () => {
    axios({
      method: "post",
      // data:{id:param.id},
      url: `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_BACKEND_URL_PREFIX}/bookings/search?id=${qrInput}`,
      headers: {
        "content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.data.result[0]) {
          setType(response.data.result[0].type);

          setDescQR(true);
          setModal2Open(false);
        } else {
          setModal2Open(false);
          setQrInput("");
          setModalErrorOpen(true);
        }
      })
      .catch((error) => {
        // localStorage.clear()
        // window.location.reload(false);
      });
  };

  const viewDetails = () => {
    if (type === "facility_type") {
      history.push({
        pathname: "/app/facilities/viewdetails",
        state: {
          id: qrInput,
        },
      });
    } else if (type === "event") {
      history.push({
        pathname: "/app/events/view_details",
        state: {
          id: qrInput,
        },
      });
    }

    setQrInput("");

    setDescQR(false);
  };

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: "#111118" }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        {navCollapsed ? (
          <Logo logoType={navMode} style={{ width: "0px" }} />
        ) : (
          <Logo logoType={navMode} />
        )}

        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          {localStorage.getItem("token") ? (
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle();
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined
                        style={{ color: "#FFFFFF" }}
                        className="nav-icon"
                      />
                    ) : (
                      <MenuFoldOutlined
                        style={{ color: "#FFFFFF" }}
                        className="nav-icon"
                      />
                    )}
                  </li>
                )}
              </ul>
            </div>
          ) : (
            <div></div>
          )}
          <div className="nav-right">
            <NavPanel
              direction={direction}
              name={name}
              id={id}
              image={image}
              inputFunction={inputFunction}
              visibleInput={visibleInput}
              wrapperRef={wrapperRef}
              modalQRVisible={modalQRVisible}
              onOpenPercentage={onOpenPercentage}
            />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
      {modal2Open && (
        <Modal
          // title="Vertically centered modal dialog"
          centered
          visible={modal2Open}
          onCancel={() => setModal2Open(false)}
          footer={[
            <Button form="myForm" type="primary" key="submit" htmlType="submit">
              Submit
            </Button>,
          ]}
        >
          <Form id="myForm" onFinish={submitForm}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <img src="/img/qr-code 1.jpg" />
              </div>
              <div
                style={{
                  color: "#12101C",
                  fontSize: "24px",
                  fontWeight: "800",
                  fontStyle: "800",
                }}
              >
                QR Code Checker
              </div>
              <div>
                Scan booking QR code or Enter Booking Id to verify details of
                bookings.
              </div>

              <Input
                ref={autoFocus}
                placeholder="Scan QR"
                value={qrInput}
                onChange={(e) => setQrInput(e.target.value)}
              />
            </div>
          </Form>
        </Modal>
      )}

      <Modal
        // title="Vertically centered modal dialog"
        centered
        visible={descQR}
        onCancel={() => setDescQR(false)}
        footer={[]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img src="/img/qr-code 1.jpg" />
          </div>
          <div
            style={{
              color: "#12101C",
              fontSize: "24px",
              fontWeight: "800",
              fontStyle: "800",
            }}
          >
            QR Code Checker
          </div>
          <div>
            Scan booking QR code or Enter Booking Id to verify details of
            bookings.
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
              background: "#FCFAFA",
            }}
          >
            <div>
              <img src="/img/QR-input.jpg" />
            </div>
            <div style={{ flex: "1" }}>
              <span style={{ color: "#000000", fontWeight: "700" }}>
                Booking ID:
              </span>{" "}
              {qrInput}
            </div>
            <Button type="primary" onClick={viewDetails}>
              View Details
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={modalErrorOpen}
        onCancel={() => {
          setModalErrorOpen(false);
          setQrInput("");
        }}
        footer={[]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img src="/img/warn.jpg" />
          </div>
          <div
            style={{
              color: "#12101C",
              fontSize: "24px",
              fontWeight: "800",
              fontStyle: "800",
            }}
          >
            Invalid QR COde Found
          </div>
          <div style={{ textAlign: "center" }}>
            QR Code scan for verification is invalid. Please scan valid QR Code
            to continue.
          </div>
          <div>
            <Button
              onClick={() => setModalErrorOpen(false)}
              style={{ border: "1px solid #1E73BE" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setModal2Open(true);
                setModalErrorOpen(false);
              }}
              style={{ backgroundColor: "#1E73BE", color: "#ffffff" }}
            >
              Scan Again
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={percentageVisible}
        title="Update GST/Service Charges"
        onCancel={onClosePercentage}
        okText={"Save"}
        confirmLoading={loading}
        onOk={onFinish}
        width={700}
      >
        <Form >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{
              width:"33%"
            }}>
              GST <span style={{ color: "red" }}>*</span>
            </div>
            <div
            style={{
              width:"33%"
            }}
            >
              <Select
                style={{
                  width: "200px",
                }}
                onChange={(e) => {
                  setServiceCharge({
                    ...serviceCharge,
                    gstSelector: e,
                  });
                }}
                value={serviceCharge.gstSelector}
              >
                <Select.Option value="percentage">Percentage</Select.Option>
                <Select.Option value="value">Flat Value</Select.Option>
              </Select>
            </div>
            <div
            style={{
              width:"33%"
            }}>
              <Input
                value={serviceCharge.gst}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const allowedKeys = [8, 9, 39]; // Allow backspace, tab, left arrow, and right arrow
                  if (
                    !(keyCode >= 48 && keyCode <= 57) &&
                    !allowedKeys.includes(keyCode)
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setServiceCharge({
                    ...serviceCharge,
                    gst: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop:"10px",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
            style={{
              width:"33%"
            }}
            >
              Service Charge <span style={{ color: "red" }}>*</span>
            </div>
            <div
            style={{
              width:"33%"
            }}
            >
              <Select
                style={{
                  width: "200px",
                }}
                onChange={(e) => {
                  setServiceCharge({
                    ...serviceCharge,
                    serviceChargeSelector: e,
                  });
                }}
                value={serviceCharge.serviceChargeSelector}
              >
                <Select.Option value="percentage">Percentage</Select.Option>
                <Select.Option value="value">Flat Value</Select.Option>
              </Select>
            </div>
            <div
            style={{
              width:"33%"
            }}
            >
              <Input
                value={serviceCharge.serviceCharge}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const allowedKeys = [8, 9, 39]; // Allow backspace, tab, left arrow, and right arrow
                  if (
                    !(keyCode >= 48 && keyCode <= 57) &&
                    !allowedKeys.includes(keyCode)
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setServiceCharge({
                    ...serviceCharge,
                    serviceCharge: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </Form>
      </Modal>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
