import Icon from "@ant-design/icons";

const Dashboard = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_905_1249)">
      <path
        d="M20.3802 8.56914L19.1502 10.4191C19.7433 11.6022 20.0337 12.914 19.9954 14.2369C19.957 15.5598 19.591 16.8525 18.9302 17.9991H5.07017C4.21134 16.5092 3.85547 14.7823 4.05531 13.0742C4.25516 11.3662 5.00008 9.76797 6.17965 8.51657C7.35922 7.26517 8.91064 6.42719 10.6039 6.12684C12.2972 5.82649 14.0422 6.07978 15.5802 6.84914L17.4302 5.61914C15.5467 4.41136 13.3125 3.87015 11.0851 4.08208C8.85763 4.29401 6.76562 5.24684 5.14367 6.78816C3.52171 8.32947 2.46353 10.3702 2.13839 12.584C1.81325 14.7977 2.23992 17.0565 3.35017 18.9991C3.52468 19.3014 3.77526 19.5528 4.077 19.7282C4.37875 19.9036 4.72115 19.997 5.07017 19.9991H18.9202C19.2726 20.0005 19.6191 19.9088 19.9247 19.7332C20.2302 19.5576 20.484 19.3044 20.6602 18.9991C21.5815 17.4031 22.0439 15.5834 21.9963 13.7411C21.9486 11.8988 21.3928 10.1055 20.3902 8.55914L20.3802 8.56914Z"
        fill="#455560"
      />
      <path
        d="M10.5905 15.4099C10.7762 15.5959 10.9968 15.7434 11.2396 15.844C11.4824 15.9447 11.7426 15.9965 12.0055 15.9965C12.2683 15.9965 12.5286 15.9447 12.7714 15.844C13.0142 15.7434 13.2347 15.5959 13.4205 15.4099L19.0805 6.91992L10.5905 12.5799C10.4045 12.7657 10.257 12.9862 10.1564 13.229C10.0557 13.4718 10.0039 13.7321 10.0039 13.9949C10.0039 14.2578 10.0557 14.518 10.1564 14.7608C10.257 15.0036 10.4045 15.2242 10.5905 15.4099Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_905_1249">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Membership = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1188_6097)">
      <path
        d="M20 2H4C2.89 2 2 2.89 2 4V15C2 16.11 2.89 17 4 17H8V22L12 20L16 22V17H20C21.11 17 22 16.11 22 15V4C22 2.89 21.11 2 20 2ZM20 15H4V13H20V15ZM20 10H4V4H20V10Z"
        fill="#54595F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1188_6097">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Events = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1188_6077)">
      <path
        d="M17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2ZM19 20H5V10H19V20ZM11 13C11 12.45 11.45 12 12 12C12.55 12 13 12.45 13 13C13 13.55 12.55 14 12 14C11.45 14 11 13.55 11 13ZM7 13C7 12.45 7.45 12 8 12C8.55 12 9 12.45 9 13C9 13.55 8.55 14 8 14C7.45 14 7 13.55 7 13ZM15 13C15 12.45 15.45 12 16 12C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14C15.45 14 15 13.55 15 13ZM11 17C11 16.45 11.45 16 12 16C12.55 16 13 16.45 13 17C13 17.55 12.55 18 12 18C11.45 18 11 17.55 11 17ZM7 17C7 16.45 7.45 16 8 16C8.55 16 9 16.45 9 17C9 17.55 8.55 18 8 18C7.45 18 7 17.55 7 17ZM15 17C15 16.45 15.45 16 16 16C16.55 16 17 16.45 17 17C17 17.55 16.55 18 16 18C15.45 18 15 17.55 15 17Z"
        fill="#54595F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1188_6077">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Facility = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1188_6090)">
      <path
        d="M16 10.53C15.71 10.24 15.23 10.24 14.94 10.53L10.59 14.88L9 13.29C8.71 13 8.23 13 7.94 13.29C7.65 13.58 7.65 14.06 7.94 14.35L9.88 16.29C10.27 16.68 10.9 16.68 11.29 16.29L15.99 11.59C16.29 11.3 16.29 10.82 16 10.53ZM19 3H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V3H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V8H19V18C19 18.55 18.55 19 18 19Z"
        fill="#54595F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1188_6090">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Gallery = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2V14H6V2H18ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM9.5 9.67L11.19 11.93L13.67 8.83L17 13H7L9.5 9.67ZM0 4V18C0 19.1 0.9 20 2 20H16V18H2V4H0Z"
      fill="#54595F"
    />
  </svg>
);

const Sports = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 3.3L12.35 2.35C14.17 2.91 15.72 4.11 16.73 5.69L16.34 7.03L14.99 7.49L11 4.7V3.3ZM7.65 2.35L9 3.3V4.7L5.01 7.49L3.66 7.03L3.27 5.69C4.28 4.12 5.83 2.92 7.65 2.35ZM5.08 15.11L3.94 15.21C2.73 13.81 2 11.99 2 10C2 9.88 2.01 9.77 2.02 9.65L3.02 8.92L4.4 9.4L5.86 13.74L5.08 15.11ZM12.5 17.59C11.71 17.85 10.87 18 10 18C9.13 18 8.29 17.85 7.5 17.59L6.81 16.1L7.45 15H12.56L13.2 16.11L12.5 17.59ZM12.27 13H7.73L6.38 8.98L10 6.44L13.63 8.98L12.27 13ZM16.06 15.21L14.92 15.11L14.13 13.74L15.59 9.4L16.98 8.93L17.98 9.66C17.99 9.77 18 9.88 18 10C18 11.99 17.27 13.81 16.06 15.21Z"
      fill="#54595F"
    />
  </svg>
);

const User = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4593_7711)">
      <path
        d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z"
        fill="#54595F"
      />
    </g>
    <defs>
      <clipPath id="clip0_4593_7711">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CMS = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1534_2943)">
      <path
        d="M17 19.22H5V7H12V5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V12H17V19.22Z"
        fill="#54595F"
      />
      <path
        d="M19 2H17V5H14C14.01 5.01 14 7 14 7H17V9.99C17.01 10 19 9.99 19 9.99V7H22V5H19V2Z"
        fill="#54595F"
      />
      <path d="M15 9H7V11H15V9Z" fill="#54595F" />
      <path d="M7 12V14H15V12H12H7Z"  fill="#54595F" />
      <path d="M15 15H7V17H15V15Z" fill="#54595F" />
    </g>
    <defs>
      <clipPath id="clip0_1534_2943">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DashboardActiveIcon = () => {
  return <Icon component={Dashboard} />;
};
export const MembershipActiveIcon = () => {
  return <Icon component={Membership} />;
};
export const EventsActiveIcon = () => {
  return <Icon component={Events} />;
};
export const FacilityActiveIcon = () => {
  return <Icon component={Facility} />;
};
export const GalleryActiveIcon = () => {
  return <Icon component={Gallery} />;
};
export const SportsActiveIcon = () => {
  return <Icon component={Sports} />;
};
export const UserActiveIcon = () => {
  return <Icon component={User} />;
};
export const CMSIcon = () => {
  return <Icon component={CMS} />;
};
